<template>
  <div class="comments">
    <div
      v-show="loading"
      class="justify-content-center"
      style="padding: 50px; margin-left: 30%"
    >
      <b-spinner
        style="width: 2.7rem; height: 2.7rem"
        variant="light"
        v-if="loading"
      ></b-spinner>
    </div>
    <div class="comment_block" v-for="comment in comment" :key="comment.id">
      <div class="comment_row" style="padding-bottom: 7px; padding-top: 7px">
        <progressive-img
          class="comment_imgs"
          :blur="0"
          :src="comment.image"
          placeholder="https://alemsesi.com/api/placeholder.webp"
          fallback="https://alemsesi.com/api/placeholder.webp"
        />
        <div class="comment_authors">
          <div :v-if="comment.u_name == ''">
            {{ comment.u_name }}
          </div>
          <div v-if="comment.u_name == null">
            {{ comment.user_name }}
          </div>
        </div>
        <div class="comment_bodys">
          {{ comment.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    comment: "",
    loading: false,
  }),
  async created() {
    this.loading = true;
    this.song_id = this.$route.params.id;
    try {
      const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?comment_list&song_id=" +
          this.song_id
      ,{headers});
      const data = await response.json();
      this.comment = data.ONLINE_MP3;
      
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.comments {
  z-index: 999;
  background: #171717;
  margin: -15px;
}

.comment_block {
  padding: 10px;
  border-bottom: 1px solid #222222;
}
.comment_imgs {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  transform: translateZ(0);

  object-fit: cover;
  padding: 2px;
  float: left;
  margin-right: 10px;
  margin-top: 4px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}
.comment_authors {
  text-align: left;
  font-size: 13px;
  font-family: "light", sans-serif;
  color: #b3b3b3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 1;
}

.comment_bodys {
  text-align: left;
  font-size: 15px;
  font-family: "light", sans-serif;
  color: #ffffff;
  margin-left: 44px;
}

.comment_title {
  color: white;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "light", sans-serif;
  text-align: left;
}
</style>
