<template>
  <div>
    <b-overlay :show="loading" variant="transparent" no-wrap> </b-overlay>
    <div v-if="loaded" style="margin-bottom: 60px">
      <div class="header_song">
        <progressive-img
          style="width: 200px; height: 200px"
          class="big_img"
          :blur="0"
          :src="song[0].mp3_thumbnail_b"
          placeholder="https://alemsesi.com/api/placeholder.webp"
          fallback="https://alemsesi.com/api/placeholder.webp"
        />
        <progressive-background
          class="blur_img"
          placeholder="https://alemsesi.com/api/placeholder.webp"
          fallback="https://alemsesi.com/api/placeholder.webp"
          :src="song[0].mp3_thumbnail_s"
        />
      </div>

      <div
        class="collapsed song_info"
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="collapse-4"
        v-on:click="visible = !visible"
      >
        <div class="song_title">{{ song[0].mp3_title }}</div>
        <div v-if="song[0].mp3_artist == 'Beýlekiler (Other)'">
          <div class="song_artist" style="display: none"></div>
          <div style="display: none">{{ artistName }}</div>
        </div>
        <div v-else>
          <div class="song_artist">{{ song[0].mp3_artist }}</div>
          <div style="display: none">
            {{ (artistName = song[0].mp3_artist) }}
          </div>
        </div>
        <div class="arrow_collap" v-show="!visible">
          <img src="@/assets/icons/arrowdown_ic.svg" />
        </div>
        <div class="arrow_collap" v-show="visible">
          <img src="@/assets/icons/arrowup_ic.svg" />
        </div>
        <div class="song_rate_data">
          <div class="total_rate">
            {{ song[0].total_rate }}
          </div>
          <div class="star_rating">
            <star-rating
              size="0.8"
              color="#f99120"
              :score="song[0].rate_avg"
              maxScore="5"
              readOnly="true"
            >
            </star-rating>
          </div>
          <div class="total_view" v-show="!visible">
            {{ song[0].total_views | formatNumber }} &nbsp; diňlendi
          </div>
          <div class="total_view" v-show="visible">
            {{ song[0].total_views }} &nbsp; diňlendi
          </div>
        </div>
      </div>
      <b-collapse id="collapse-4" v-model="visible">
        <div class="song_info_collapsed">
          <div class="down_count">
            {{ song[0].total_download }} &nbsp; ýüklendi
          </div>

          <div
            class="song_desc"
            v-html="song[0].mp3_description"
          ></div>
        </div>
      </b-collapse>
      <div class="song_control">
        <b-container>
          <b-row cols="4">
            <b-col
              class="btn_col"
              @click="likeSong('5')"
            >
              <img
                v-if="song[0].user_rate == null || song[0].user_rate == 0 || song[0].user_rate == 1"
                class="control_ic"
                src="@/assets/icons/like_ic.svg"
              />
              <img
                v-else-if="song[0].user_rate == 5"
                class="control_ic"
                src="@/assets/icons/liked_ic.svg"
              />
              <div class="control_tv">{{ song[0].count_like }}</div>
            </b-col>
            <b-col
              class="btn_col"
              @click="dislikeSong('1')"
            >          
              <img
                v-if="song[0].user_rate == null || song[0].user_rate == 0 || song[0].user_rate == 5" 
                class="control_ic"
                src="@/assets/icons/dislike_ic.svg"
              />
              <img
                v-else-if="song[0].user_rate == 1"
                class="control_ic"
                src="@/assets/icons/disliked_ic.svg"
              />
              <div class="control_tv">{{ song[0].dislike }}</div>
            </b-col>
            <b-col
              class="btn_col"
              @click="shareViaWebShare(song[0].mp3_title, artistName)"
            >
              <img class="control_ic" src="@/assets/icons/share_ic.svg" />
              <div class="control_tv">Paýlaş</div>
            </b-col>
            
            <a :href='song[0].mp3_url'  class="btn_col" download="file.mp3">
              <img class="control_ic" src="@/assets/icons/download_ic.svg" />
              <div class="control_tv">Ýükle</div>
            </a>
          </b-row>
        </b-container>
      </div>

      <div
        class="comment_block"
        v-b-modal.modal-scrollable
        @click="isPanel = true"
        v-for="comment in comment"
        :key="comment.id"
        
      >
        <div class="comment_title">
          Teswirler &nbsp;{{ song[0].total_comments }}
        </div>
        <div class="comment_row">
          <progressive-img
            class="comment_img"
            :blur="0"
            :src="comment.image"
            placeholder="https://alemsesi.com/api/placeholder.webp"
            fallback="https://alemsesi.com/api/placeholder.webp"
          />
          <div class="comment_author">
            <div :v-if="comment.u_name == ''">
              {{ comment.u_name }}
            </div>
            <div v-if="comment.u_name == null">
              {{ comment.user_name }}
            </div>
          </div>
          <div class="comment_body">
            {{ comment.comment }}
          </div>
        </div>
      </div>

      <div class="songs">
        <div class="songs_title">Related songs</div>
        <div
          class="song"
          v-for="song_list in song_list"
          :key="song_list.id"
          
        >
          <router-link
            :to="{
              name: 'SongDetail',
              params: {
                id: song_list.id,
                slug: song_detail(song_list.mp3_title),
                singer: song_list.mp3_artist,
                title: song_list.mp3_title,
              },
              query: { singer: getSinger(song_list.mp3_artist) },
            }"
          >
            <progressive-img
              class="song_img_l"
              :blur="0"
              placeholder="https://alemsesi.com/api/placeholder.webp"
              fallback="https://alemsesi.com/api/placeholder.webp"
              :src="song_list.mp3_thumbnail_s"
            />

            <div class="song_data_l">
              <div class="s_title">{{ song_list.mp3_title }}</div>
              <div v-if="song_list.mp3_artist == 'Beýlekiler (Other)'">
                <div class="s_artist">{{ song_list.category_name }}</div>
              </div>
              <div v-else>
                <div class="s_artist">{{ song_list.mp3_artist }}</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <vue-plyr>
        <audio playsinline>
          <source :src="convertHttpsToHttp(song[0].mp3_url)" type="audio/mpeg" />
        </audio>
      </vue-plyr>
    </div>

    <div v-if="notFound">
      <b-alert style="margin: 20px; margin-top: 20px" show variant="dark"
        >Sahypa tapylmady</b-alert
      >
    </div>
    <div v-show="loading"></div>

    <b-modal id="modal-scrollable" scrollable button-size="sm">
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->

        <h5>Teswirler</h5>
      </template>
      <div v-if="isPanel">
        <CommenListVue />
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="danger" @click="cancel()"> Öçür </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>
<style>
.b-overlay .spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.1em solid #fff;
  border-right: 0.1em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.plyr--playing {
  position: absolute;
}

.plyr button {
  font: inherit;
  right: 20px;
  width: 50px !important;
  position: absolute !important;
  top: 175px;
  height: 50px;
  border-radius: 100% !important;
  background: #fff !important;
  line-height: inherit;
  width: auto;
}
.plyr--full-ui input[type="range"] {
  color: #cc3737 !important;
}
.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  display: none;
  position: relative;
  width: 20%;
}
.plyr__menu {
  display: none !important;
  position: relative;
}
.plyr__volume {
  align-items: center;
  display: none !important;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}
.plyr__control svg {
  display: block;
  fill: #000 !important;
  height: 22px !important;
  width: 36px !important;
  /* width: var(--plyr-control-icon-size,18px); */
}
.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(10px / 2);
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
  position: absolute;
  top: 230px;
  left: 5px;
  color: white;
}
.plyr__progress {
  left: calc(13px * 0.5);
  left: calc(var(--plyr-range-thumb-height, 13px) * 0.5);
  margin-right: 8px;
  margin-right: var(--plyr-range-thumb-height, 8px);
  position: absolute !important;
  right: 0px;
  border-radius: 0px !important;
  top: 252px;
}
.plyr__volume input[type="range"] {
  display: none !important;
}
.plyr--audio .plyr__controls {
  background: none !important;
  border-radius: inherit;
  color: #4a5464;

  padding: 0px !important;
  /* padding: var(--plyr-control-spacing,10px); */
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  /* height: 100%; */
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  /* min-width: 200px; */
  position: unset !important;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}
[data-plyr="airplay"] {
  display: none !important;
}

.modal-header {
  background: #131313;
  color: #fff;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 5px;
  font-family: "bold", sans-serif;
  border-bottom: 1px solid #202020;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  background: #171717;
  color: white;
  font-family: "light", sans-serif;
}
.modal-content {
  border: none;
  background: #131313 !important;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  background: #131313;
  align-items: center;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: flex-end;
  /* padding: 0.75rem; */
  border-top: 1px solid #202020;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
</style>
<style></style>

<script>
import starRating from "vue-star-rate";
import axios from "axios";
import CommenListVue from "./CommenList.vue";



export default {
  components: {
    starRating,
    CommenListVue,
    
    
  },

  name: "SongDetail",
  data: () => ({
    cross: "https://cors-anywhere.herokuapp.com/",
    isPanel: false,
    song: "",
    loaded: true,
    loading: false,
    userData: {},
    notFound: false,
    visible: false,
    artistName: "",
    comment: "",
    song_id: "",
    panelState: "collapsed",
    gravity: "bottom",
    artistRelated: "",
    song_list: "",
    componentKey: 0,
    artistOther: "",
    downloading: "",
    metaTitle: "",
    metaDescription: "",
    totalView: "",
    totalDownload: "",
    mp3_url: "",
    mp3_img: "",
    category: "",
    m_title: "",
  }),
  metaInfo() {
    if ((this.artistOther == "") & (this.metaTitle == "")) {
      this.m_title = "Älem Sesinde diňle";
    } else {
      this.m_title = this.artistOther + this.metaTitle + " | Älem Sesi";
    }
    return {
      title: `${this.m_title}`,
      meta: [
        {
          name: "description",
          content:
            this.artistOther +
            this.metaTitle +
            " Bu aýdym Älem Sesinde " +
            this.totalView +
            " gezek diňlendi, " +
            this.totalDownload +
            " gezek ýüklendi",
        },
        {
          property: "og:title",
          content: this.artistOther + this.metaTitle + " |  Älem Sesi",
        },
        { property: "og:site_name", content: "Älem Sesi" },
        { property: "og:type", content: "music.song" },
        { property: "og:image:type", content: "image/jpeg" },
        { property: "og:image:width", content: "500" },
        { property: "og:image:height", content: "500" },
        { property: "music:musician", content: this.artistOther },

        { name: "robots", content: "index,follow" },
        {
          property: "og:url",
          content:
            "https://m.alemsesi.com/song/" +
            this.song_id +
            "/" +
            this.metaTitle,
        },
        { property: "og:image", content: this.mp3_img },
      ],
    };
  },
  async created() {
    const vm = this;
    this.loading = true;
    this.loaded = false;
    this.notFound = false;
    
    var id_song = this.$route.params.id;
    this.song_id = this.$route.params.id;

    try {
      const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?single_song&song_id=" +
          id_song +
          "&device_id=" +
          this.$cookies.get("_getuser")
      ,{headers});
      const data = await response.json();
      this.song = data.ONLINE_MP3;

      

      if (this.song.length > 0) {
        this.loaded = true;
        this.notFound = false;
        this.metaTitle = this.song[0].mp3_title;
        this.totalDownload = this.song[0].total_download;
        this.mp3_img = this.song[0].mp3_thumbnail_s;

        this.totalView = this.song[0].total_views;
        this.mp3_url = this.song[0].mp3_url;
        this.category = this.song[0].category_name;
        if (this.song[0].mp3_artist == "Beýlekiler (Other)") {
          this.artistRelated = this.song[0].category_name;
          this.artistOther = "";
        } else {
          this.artistRelated = this.song[0].mp3_artist;
          this.artistOther = this.song[0].mp3_artist + " - ";
        }
        const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
        const relatedSong = await fetch(
          "https://alemsesi.com/api/wep_api.php?random_single&related_song=" +
            this.artistRelated
        );
        const related = await relatedSong.json();
        this.song_list = related.ONLINE_MP3;
      } else {
        this.metaTitle = "404 Not found";
        this.loaded = false;
        this.notFound = true;
        
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loaded = false;
      this.loading = false;
      this.notFound = false;
      this.metaTitle = "404 Not found";
    }
  },

  async mounted() {
    this.loadComment = true;
    this.notComment = false;
    
    
    try {
      const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?comment_one&song_id=" +
          this.song_id
      ,);
      const data = await response.json();
      this.comment = data.ONLINE_MP3;
      
      if (this.comment.length > 0) {
        this.loadComment = false;
        this.notComment = false;
      } else {
        this.loadComment = false;
        this.notComment = true;
      }
      this.loadComment = false;
    } catch (error) {
      console.log(error);
      this.loadComment = false;
      this.notComment = false;
    }
  },

  computed: {
    webShareApiSupported() {
      return navigator.share;
    },
    getFullPath() {
      return this.$route.path;
    },
  },
  watch: {
    getFullPath() {
      this.getData();
    },
  },
  
    
  methods: {
    convertHttpsToHttp(href) {
      if (href.startsWith("http://")) {
        return "https://" + href.substring(7);
      }
      return href;
    },
    
    async getData() {
      const vm = this;
      this.loading = true;
      this.loaded = false;
      this.notFound = false;
      
      var id_song = this.$route.params.id;
      this.song_id = this.$route.params.id;

      try {
        const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?single_song&song_id=" +
            id_song
        );
        const data = await response.json();
        this.song = data.ONLINE_MP3;

        if (this.song.length > 0) {
          this.loaded = true;
          this.notFound = false;
          vm.metaTitle = this.song[0].mp3_title;
          this.totalDownload = this.song[0].total_download;
          this.totalView = this.song[0].total_views;
          this.mp3_img = this.song[0].mp3_thumbnail_s;
          this.category = this.song[0].category_name;
          this.mp3_url = this.song[0].mp3_url;
          if (this.song[0].mp3_artist == "Beýlekiler (Other)") {
            this.artistRelated = this.song[0].category_name;
            this.artistOther = "";
          } else {
            this.artistRelated = this.song[0].mp3_artist;
            this.artistOther = this.song[0].mp3_artist + " - ";
          }
          
          const relatedSong = await fetch(
            "https://alemsesi.com/api/wep_api.php?random_single&related_song=" +
              this.artistRelated
          );
          const related = await relatedSong.json();
          this.song_list = related.ONLINE_MP3;
        } else {
          vm.metaTitle = "404 Not found";
          this.loaded = false;
          this.notFound = true;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loaded = false;
        this.loading = false;
        this.notFound = false;
        vm.metaTitle = "404 Not found";
      }
      this.loadComment = true;
      this.notComment = false;
      
      try {
        
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?comment_one&song_id=" +
            this.song_id
        );
        const data = await response.json();
        this.comment = data.ONLINE_MP3;
        
        if (this.comment.length > 0) {
          this.loadComment = false;
          this.notComment = false;
        } else {
          this.loadComment = false;
          this.notComment = true;
        }
        this.loadComment = false;
      } catch (error) {
        console.log(error);
        this.loadComment = false;
        this.notComment = false;
      }
    },
    showMsgBoxTwo(title) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk(
          this.artistOther +
            " " +
            title +
            "  aýdym arka tarapda telefonyňyza ýüklenýär. Ýüklenenden soňra habar ediler",
          {
            title: "Ýüklenýär",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        )
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },
    downloadMp3(url, title) {
      this.$downloadStatus = "downloading";
      this.showMsgBoxTwo(title);
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        this.$downloadStatus = "Downloaded";
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");

        fURL.href = fileURL;
        fURL.setAttribute("download", this.artistOther + title + ".mp3");
        document.body.appendChild(fURL);

        fURL.click();
      });
    },
    clickedDownload: function(url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "");
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
    async downloadCount() {
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?song_download&song_id=" +
          this.$route.params.id
      );
      const data = await response.json();
      
    },

    shareViaWebShare(title, artistName) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");

      slug = slug.replace(/c|ç/gi, "c");

      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ý/gi, "y");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      navigator.share({
        title: title,
        text: artistName + " " + title,
        url: slug,
      });
    },
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
    getSinger(text) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        
        var catsName = this.category;
        var catName = catsName.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
    },
    async likeSong(rate) {
      
      
      if (this.$cookies.get("_getuser")) {
        
          const response = await fetch(
            "https://alemsesi.com/api/wep_api.php?song_rating&user_id=" +
              this.$cookies.get("_getuser") +
              "&post_id=" +
              this.song[0].id +
              "&rate=" +
              rate
          );
          const data = await response.json();
          
          if (data.ONLINE_MP3[0].success == 1) {
            this.song[0].count_like++;
            this.song[0].user_rate = 5
            if(this.song[0].id >= 2939){
              this.earnCount();
            }
          }
         
      }else{
        this.showMsgBoxOne()
      }
    },
    async dislikeSong(rate) {
      
      if (this.$cookies.get("_getuser")) {
        
          const response = await fetch(
            "https://alemsesi.com/api/wep_api.php?song_rating&user_id=" +
              this.$cookies.get("_getuser") +
              "&post_id=" +
              this.song[0].id +
              "&rate=" +
              rate
          );
          const data = await response.json();
          
          
          if (data.ONLINE_MP3[0].success == 1) {
            this.song[0].dislike++;
            this.song[0].user_rate = 1
            if(this.song[0].id >= 2939){
              this.earnCount();
            }
            
          }
         
      }
      else{
        this.showMsgBoxOne()
      }
    },

showMsgBoxOne() {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm('Ähli mümkinçiliklerden peýdalanmak üçin içeri giriň', {
          okTitle: "Içeri gir",
          cancelTitle: "Gerek däl",
          okVariant: "danger",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$router.push("/profile");
          } 
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async earnCount() {
      
      if (this.$cookies.get("_getuser")) {
          const response = await fetch(
            "https://alemsesi.com/api/wep_api.php?earn_count&user_id=" +
              this.$cookies.get("_getuser") +
              "&count_price=" +
              '0.04'
          );
          const data = await response.json();
         
      }
    },
  },
};
</script>
